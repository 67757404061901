import Component from "../../../../shared/js/common/component";
import Swiper from 'swiper';
import {Navigation, Controller} from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class Teaser extends Component {
    static readonly componentName: string = "skiLocationTeaserColumnsArea";

    swiper: Swiper;
    skiLocationTopSlider: Swiper;
    skiLocationMainSlider: Swiper;
    sliderElement: HTMLElement;
    skiLocationNightTopSlider: Swiper;
    skiLocationNightMainSlider: Swiper;
    defaultMainSwiperOptions: SwiperOptions = {
        spaceBetween: 30,
        centeredSlides: true,
        slidesPerView: 'auto',
        watchOverflow: true,

    }
    defaultTopSwiperOptions: SwiperOptions = {
        slidesPerView: 'auto',
        centeredSlides: true,
    }

    init(): void {
        this.registerEvents();
        window.onresize = () => {
            this.registerEvents();
        }
    }

    registerEvents() {
        if (window.matchMedia("(max-width: 575px)").matches) {
            this.initSliders();
        } else {
            this.destroySlider();
        }
    }

    initSliders() {
        Swiper.use([Navigation, Controller]);

        const swiperMainNavigation: SwiperOptions = {
            navigation: {
                nextEl: '.swiper-button-next.main',
                prevEl: '.swiper-button-prev.main',
            },
        }
        const mainOptions = Object.assign({}, this.defaultMainSwiperOptions, swiperMainNavigation);
        const swiperMainNightNavigation: SwiperOptions = {
            navigation: {
                nextEl: '.swiper-button-next.main-night',
                prevEl: '.swiper-button-prev.main-night',
            },
        }
        const mainNightOptions = Object.assign({}, this.defaultMainSwiperOptions, swiperMainNightNavigation);

        this.skiLocationTopSlider = new Swiper('.ski-location-top-slider', this.defaultTopSwiperOptions);
        this.skiLocationMainSlider = new Swiper('.ski-location-main-slider', mainOptions);

        this.skiLocationTopSlider.controller.control = this.skiLocationMainSlider;
        this.skiLocationMainSlider.controller.control = this.skiLocationTopSlider;

        this.skiLocationNightTopSlider = new Swiper('.ski-location-night-top-slider', this.defaultTopSwiperOptions);
        this.skiLocationNightMainSlider = new Swiper('.ski-location-night-main-slider', mainNightOptions);

        this.skiLocationNightTopSlider.controller.control = this.skiLocationNightMainSlider;
        this.skiLocationNightMainSlider.controller.control = this.skiLocationNightTopSlider;
    }

    destroySlider() {
        if (this.skiLocationTopSlider) {
            this.skiLocationTopSlider.destroy();
        }
        if (this.skiLocationNightTopSlider) {
            this.skiLocationNightTopSlider.destroy();
        }
        if (this.skiLocationNightMainSlider) {
            this.skiLocationNightMainSlider.destroy();
        }
        if (this.skiLocationMainSlider) {
            this.skiLocationMainSlider.destroy();
        }
    }
}