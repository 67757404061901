import Component from "../../../../shared/js/common/component";

export default class Header extends Component {
    static readonly componentName: string = 'header-wrapper';
    isMobile = window.matchMedia('(max-width: 576px)').matches;

    init() {
        if (this.isMobile) {
            document.body.className = 'fullscreen';
        }
        this.handleInfoBox(
            '.icon-wrapper__icon img[src*="header_cloud-icon.svg"]',
            '.weather-info-box',
            '.box.left',
            '.icon-wrapper__icon img[src*="header_snowflake-icon.svg"]',
            '.snow-info-box',
            '.box.right.snow',
            '.icon-wrapper__icon img[src*="header_bus-icon.svg"]',
            '.departure-info-box',
            '.box.right.departure',
        );
        this.handleSkiLocationSelector('.weather-location-filter', '.weather-location-filter-link', '.weather-locations', '.weather-location-name-title', '.temperature-list');
        this.handleSkiLocationSelector('.snow-location-filter', '.snow-location-filter-link', '.snow-locations', '.snow-location-name-title', '.snow-list');
        this.handleSkiLocationSelector('.departure-location-filter', '.departure-location-filter-link', '.departure-locations', '.departure-location-name-title', '.departure-list');
        this.departureTimesLinkToggle('.departure-times-link');
        this.tooltipHandling('.skibus-tooltip');
    }

    handleInfoBox(
        weatherIconSelector: string,
        weatherInfoBoxSelector: string,
        weatherInfoBoxSideSelector: string,
        snowIconSelector: string,
        snowInfoBoxSelector: string,
        snowInfoBoxSideSelector: string,
        departureIconSelector: string,
        departureInfoBoxSelector: string,
        departureInfoBoxSideSelector: string,
    ) {
        const weatherIcon = document.querySelector(weatherIconSelector) as HTMLElement;
        const weatherInfoBox = document.querySelector(weatherInfoBoxSelector) as HTMLElement;
        const weatherInfoBoxSide = document.querySelector(weatherInfoBoxSideSelector) as HTMLElement;

        const snowIcon = document.querySelector(snowIconSelector) as HTMLElement;
        const snowInfoBox = document.querySelector(snowInfoBoxSelector) as HTMLElement;
        const snowInfoBoxSide = document.querySelector(snowInfoBoxSideSelector) as HTMLElement;

        const departureIcon = document.querySelector(departureIconSelector) as HTMLElement;
        const departureInfoBox = document.querySelector(departureInfoBoxSelector) as HTMLElement;
        const departureInfoBoxSide = document.querySelector(departureInfoBoxSideSelector) as HTMLElement;

        weatherIcon.addEventListener(this.isMobile ? 'click' : 'mouseenter', () => this.handleMouseEnter(weatherIcon, weatherInfoBox, weatherInfoBoxSide, snowIcon, snowInfoBox, snowInfoBoxSide, departureIcon, departureInfoBox, departureInfoBoxSide));
        snowIcon.addEventListener(this.isMobile ? 'click' : 'mouseenter', () => this.handleMouseEnter(snowIcon, snowInfoBox, snowInfoBoxSide, weatherIcon, weatherInfoBox, weatherInfoBoxSide, departureIcon, departureInfoBox, departureInfoBoxSide));
        departureIcon.addEventListener(this.isMobile ? 'click' : 'mouseenter', () => this.handleMouseEnter(departureIcon, departureInfoBox, departureInfoBoxSide, weatherIcon, weatherInfoBox, weatherInfoBoxSide, snowIcon, snowInfoBox, snowInfoBoxSide));

        if (!this.isMobile) {
            weatherInfoBox.addEventListener('mouseleave', () => this.toggleInfoBox(weatherIcon, weatherInfoBox, weatherInfoBoxSide, false));
            snowInfoBox.addEventListener('mouseleave', () => this.toggleInfoBox(snowIcon, snowInfoBox, snowInfoBoxSide, false));
            departureInfoBox.addEventListener('mouseleave', () => this.toggleInfoBox(departureIcon, departureInfoBox, departureInfoBoxSide, false));
        }

        // handle click outside the box -> close
        document.addEventListener('click', (event) => {
            if (!weatherIcon.contains(event.target as Node) &&
                !weatherInfoBox.contains(event.target as Node) &&
                !snowIcon.contains(event.target as Node) &&
                !snowInfoBox.contains(event.target as Node) &&
                !departureIcon.contains(event.target as Node) &&
                !departureInfoBox.contains(event.target as Node)) {
                this.toggleInfoBox(weatherIcon, weatherInfoBox, weatherInfoBoxSide, false);
                this.toggleInfoBox(snowIcon, snowInfoBox, snowInfoBoxSide, false);
                this.toggleInfoBox(departureIcon, departureInfoBox, departureInfoBoxSide, false);
            }
        });
    }

    handleMouseEnter(
        activeIcon: HTMLElement,
        activeInfoBox: HTMLElement,
        activeInfoBoxSide: HTMLElement,
        inactiveIcon: HTMLElement,
        inactiveInfoBox: HTMLElement,
        inactiveInfoBoxSide: HTMLElement,
        departureIcon: HTMLElement,
        departureInfoBox: HTMLElement,
        departureInfoBoxSide: HTMLElement
    ) {
        this.toggleInfoBox(inactiveIcon, inactiveInfoBox, inactiveInfoBoxSide, false);
        this.toggleInfoBox(activeIcon, activeInfoBox, activeInfoBoxSide, true);
        this.toggleInfoBox(departureIcon, departureInfoBox, departureInfoBoxSide, false);
    }

    toggleInfoBox(icon: HTMLElement, infoBox: HTMLElement, infoBoxSide: HTMLElement, isActive: boolean) {
        if (this.isMobile && icon.classList.contains('active')) {
            icon.classList.remove('active');
            infoBox.classList.remove('active');
            infoBoxSide.classList.remove('active');
        } else {
            infoBox.classList.toggle('active', isActive);
            icon.classList.toggle('active', isActive);
            infoBoxSide.classList.toggle('active', isActive);
        }
    }

    handleSkiLocationSelector(locationSelector: string, selectorLink: string, locationsListSelector: string, locationNameTitleSelector: string, listSelector?: string) {
        window.addEventListener('DOMContentLoaded', (e) => {
            const skiLocations = document.querySelectorAll(locationSelector);
            const locationSelectorElement = document.querySelector(selectorLink);
            const locationsList = document.querySelector(locationsListSelector);
            const locationNameTitle = document.querySelector(locationNameTitleSelector) as HTMLElement;

            if (skiLocations.length === 0) {
                return;
            }

            locationNameTitle.innerText = skiLocations[0].getAttribute('data-name');

            if (locationSelectorElement && locationsList) {
                locationSelectorElement.addEventListener('click', (event) => {
                    locationSelectorElement.classList.toggle('open');
                    locationsList.classList.toggle('open');
                });
            }

            skiLocations.forEach((locationLink) => {
                locationLink.addEventListener('click', (event) => {
                    event.preventDefault();

                    skiLocations.forEach((link) => {
                        link.classList.remove('active');
                    });

                    locationLink.classList.add('active');
                    locationsList.classList.remove('open');
                    locationSelectorElement.classList.remove('open');

                    if (locationNameTitle) {
                        locationNameTitle.innerText = locationLink.getAttribute('data-name');
                    }

                    if (listSelector) {
                        const departureList = document.querySelector(listSelector);
                        const filterKey = locationLink.getAttribute('data-filter');
                        const departureListItems = departureList.querySelectorAll('li');
                        const dataMaxVisibleItems = parseInt(departureList.getAttribute('data-max-visible-items'));
                        const maxVisibleItems = dataMaxVisibleItems > 0 ? dataMaxVisibleItems : -1;

                        let visibleCount = 0;

                        departureListItems.forEach((item) => {
                            const locationId = item.getAttribute('data-location');

                            // Check if item matches the selected location filter
                            const matchesFilter = filterKey === "all" || item.getAttribute('data-id') === filterKey;
                            const isVisible = (maxVisibleItems == -1 || visibleCount < maxVisibleItems);

                            // Show item if it matches and visible count is less than 7
                            if (matchesFilter && isVisible) {
                                item.classList.add('active')
                                visibleCount++;
                            } else {
                                item.classList.remove('active');
                            }
                        });

                    }
                });
            });
        }, {once: true});
    }

    departureTimesLinkToggle(departureTimesLinkSelector: string) {
        document.querySelectorAll(departureTimesLinkSelector).forEach(link => {
            link.addEventListener('click', function(e)  {
                e.preventDefault();
                const departureTimesWrapper = this.nextElementSibling as HTMLElement;
                departureTimesWrapper.classList.toggle('open');
                link.classList.toggle('open');
            });
        });
    }

    tooltipHandling(toolTipSelector: string) {
        const toolTipTrigger = this.isMobile ? 'click' : 'mouseenter';

        document.querySelectorAll(toolTipSelector).forEach((tooltip) => {
            tooltip.addEventListener(toolTipTrigger, (e) => {
                e.preventDefault();
                tooltip.classList.toggle('open');

                const contentElement = tooltip.querySelector('.skibus-tooltip__content') as HTMLElement;
                const contentHeight = contentElement.offsetHeight;
                contentElement.style.top = `calc(-${contentHeight}px)`;
            });

            if (this.isMobile) {
                document.body.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;
                    if (!tooltip.contains(target)) {
                        tooltip.classList.remove('open');
                    }
                });
            } else {
                tooltip.addEventListener('mouseleave', () => {
                    tooltip.classList.remove('open');
                });
            }
        });
    }
}
