import Component from "../../../../shared/js/common/component";

export default class HeaderOverlay extends Component {
    static readonly componentName: string = 'headerOverlay';

    init() {
        const headerOverlayWrapper = document.querySelector('.seasonal-header-overlay__wrapper') as HTMLElement;
        const headerOverlayButton = this.element.querySelector('.seasonal-header-overlay__button') as HTMLButtonElement;
        headerOverlayButton?.addEventListener('click', () => {
            headerOverlayWrapper?.classList.add('hide');
        });
    }
}