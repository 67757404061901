import Component from "../../../../shared/js/common/component";
import bootstrap from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class SkiDepartureTimesAccordion extends Component {
    static readonly componentName: string = "departureTimesTable";

    departureTimeAccordionItems: HTMLElement[];

    init(): void {
        this.departureTimeAccordionItems = Array.from(this.element.getElementsByClassName('departure-times-table__accordion')) as HTMLElement[];

        this.mobileDepartureAccordion();
    }

    mobileDepartureAccordion() {
        this.departureTimeAccordionItems.forEach((element: HTMLElement) => {
            element.addEventListener('click', (e) => {
                (e.currentTarget as HTMLElement).classList.toggle('show');
                const collapseContent = (e.currentTarget as HTMLElement).parentNode.querySelector('.departure-times-table__accordion-content');
                new bootstrap.Collapse(collapseContent, {});
            });
        });
    }
}