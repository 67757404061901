import Component from "../../../../shared/js/common/component";
import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class HeaderSlider extends Component {
    static readonly componentName: string = 'headerStageSlider';

    swiper: Swiper;

    init() {
        this.initSwiper();
    }

    initSwiper() {
        const sliderPagination = this.element.querySelector('.swiper-pagination') as HTMLElement;
        Swiper.use([Autoplay, Pagination]);
        this.swiper = new Swiper('.swiper', {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: sliderPagination,
                type: 'bullets',
                clickable: true,
            },
        })
    }

}