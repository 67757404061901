/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';

import Teaser from "./bricks/teaser";
import MobileNavigation from "./bricks/navigation/mobile-navigation";
import HeaderSlider from "./common/header-slider";
import Header from "./common/header";
import SkiLocationTeaserBox from "./bricks/ski-location-teaser-box";
import SkiLocationFacts from "./bricks/location-facts";
import SkiDepartureTimesAccordion from "./bricks/departure-times-accordion";
import HeaderOverlay from "./common/header-overlay";
import Userlike from "../../aob/js/userlike/userlike";

/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        MobileNavigation.initialize();
        HeaderSlider.initialize();
        Header.initialize();
        Teaser.initialize();
        SkiLocationTeaserBox.initialize();
        HeaderOverlay.initialize();
        Userlike.initialize();
        SkiLocationFacts.initialize();
        SkiDepartureTimesAccordion.initialize();
    }

}

export default Application;