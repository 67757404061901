import Component from "../../../../shared/js/common/component";
import bootstrap from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class SkiLocationFacts extends Component {
    static readonly componentName: string = "location-facts";

    locationFactsAccordionItems: HTMLElement[];

    init(): void {
        this.locationFactsAccordionItems = Array.from(this.element.getElementsByClassName('location-facts__accordion')) as HTMLElement[];

        this.mobileLocationFactsAccordion();
    }

    mobileLocationFactsAccordion() {
        this.locationFactsAccordionItems.forEach((element: HTMLElement) => {
            element.addEventListener('click', (e) => {
                const collapseContent = (e.currentTarget as HTMLElement).querySelector('.location-facts__mobile-list');
                new bootstrap.Collapse(collapseContent, {});
            });
        });
    }
}