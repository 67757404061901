import Component from "../../../../shared/js/common/component";
import bootstrap from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class SkiLocationTeaserBox extends Component {
    static readonly componentName: string = "toolTipBox";

    init(): void {
        const tooltipTriggers = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipOptions = {
            position: 'top',
            offset: [10,20]
        };
        tooltipTriggers.forEach((tooltipTrigger) => {
            new bootstrap.Tooltip(tooltipTrigger, tooltipOptions);
        });
    }
}